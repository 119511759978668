exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-experience-jsx": () => import("./../../../src/pages/experience.jsx" /* webpackChunkName: "component---src-pages-experience-jsx" */),
  "component---src-pages-experience-resume-jsx": () => import("./../../../src/pages/experience/resume.jsx" /* webpackChunkName: "component---src-pages-experience-resume-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-tools-skills-jsx": () => import("./../../../src/pages/toolsSkills.jsx" /* webpackChunkName: "component---src-pages-tools-skills-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */),
  "component---src-templates-experience-details-jsx": () => import("./../../../src/templates/experience-details.jsx" /* webpackChunkName: "component---src-templates-experience-details-jsx" */),
  "component---src-templates-work-details-jsx": () => import("./../../../src/templates/work-details.jsx" /* webpackChunkName: "component---src-templates-work-details-jsx" */)
}

