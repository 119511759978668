import React, { createContext, useState, useEffect } from "react"

export const Context = createContext()

export const ContextProvider = ({ children }) => {
  const [category, setCategory] = useState("All")
  const [isServer, setIsServer] = useState(true)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsServer(false)
    }
  }, [isServer])

  return (
    <Context.Provider
      value={{
        category,
        setCategory,
        isServer,
      }}
    >
      {children}
    </Context.Provider>
  )
}
